import { Spin } from "antd"
import React from "react"

export default function Loading() {
   return (
      <div className='flex flex-1 items-center justify-center'>
         <Spin />
      </div>
   )
}
