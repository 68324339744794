import { errorResponse } from "../helpers/response"
import { doAuthorizedGetCall, doAuthorizedPostCall } from "./axiosHelper"

export async function getAllDispatchers() {
   try {
      const response = await doAuthorizedGetCall("/api/dispatcher/all")
      return response.data
   } catch (e) {
      return errorResponse("Error while fetching dispatchers", e)
   }
}

export async function updateDispatcher(data) {
   try {
      const response = await doAuthorizedPostCall(
         "/api/dispatcher/update",
         data
      )
      return response.data
   } catch (e) {
      return errorResponse("Error while updating dispatcher", e)
   }
}

export async function deleteDispatcher(deleteData) {
   return (await doAuthorizedPostCall("/api/dispatcher/delete", deleteData))
      .data
}

export async function updateDispatcherPassword(data) {
   return (await doAuthorizedPostCall("/api/dispatcher/update-password", data))
      .data
}
