import React, { useEffect, useState } from "react"
import {
   deleteDrivers,
   getAllDrivers,
} from "../../../controllers/driversController"
import UpdateDriver from "./driversComponents/UpdateDriver"
import { sendErrorNotification } from "../../../helpers/notification"
import {
   Button,
   Checkbox,
   Input,
   message,
   Popconfirm,
   Spin,
   Table,
   Tag,
} from "antd"
import { DeleteOutlined } from "@ant-design/icons"

export default function DriversView() {
   const columns = [
      {
         title: () => {
            return (
               <Checkbox
                  onChange={(x) => {
                     setDrivers(
                        drivers.map((d) => {
                           return { ...d, selected: x.target.checked }
                        })
                     )
                  }}
                  checked={drivers.every((d) => d.selected)}
                  className=''>
                  Full Name
               </Checkbox>
            )
         },
         dataIndex: "fullname",
         key: "name",
         render: (text, record) => (
            <Checkbox
               onChange={(x) => {
                  setDrivers(
                     drivers.map((d) => {
                        if (d.id === record.id) {
                           return { ...d, selected: x.target.checked }
                        }

                        return d
                     })
                  )
               }}
               checked={record.selected}
               className=''>
               {text}
            </Checkbox>
         ),
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "Phone",
         dataIndex: "phone",
         key: "phone",
      },
      {
         title: "Trips Count",
         dataIndex: "tripsCount",
         key: "tripsCount",
         render: (t, r) => {
            if (!t) return "0"

            return t
         },
      },
      {
         title: "Status",
         dataIndex: "verification",
         key: "status",
         render: (v, r) => {
            if (v === null) {
               v = {
                  status: 0,
                  level: 1,
               }
            }
            return (
               <Tag
                  color={
                     v.status === 0
                        ? "yellow"
                        : v.status === 1
                        ? "blue"
                        : v.status === 2
                        ? "green"
                        : "red"
                  }>
                  {v.status === 0
                     ? "Not Selected"
                     : v.status === 1
                     ? "Pending"
                     : v.status === 2
                     ? "Approved"
                     : "Blocked"}
               </Tag>
            )
         },
      },
      {
         title: "Action",
         key: "action",
         render: (v, r) => {
            return (
               <UpdateDriver
                  key={"update" + r.id}
                  onDelete={() => {
                     setDrivers(drivers.filter((d) => d.id !== r.id))
                  }}
                  onUpdate={(updateModel) => {
                     setDrivers(
                        drivers.map((driver) => {
                           if (driver.id === r.id) {
                              return {
                                 ...driver,
                                 ...{
                                    verification: {
                                       status: updateModel.status,
                                       level: updateModel.level,
                                    },
                                 },
                              }
                           }
                           return driver
                        })
                     )
                  }}
                  driver={r}
               />
            )
         },
      },
   ]
   const [drivers, setDrivers] = useState([])
   const [filteredDrivers, setFilteredDrivers] = useState([])
   const [search, setSearch] = useState("")
   const [loading, setLoading] = useState(false)

   useEffect(() => {
      setFilteredDrivers(
         drivers.filter((d) => d.fullname.toLowerCase().includes(search))
      )
   }, [search, drivers])

   useEffect(() => {
      const fetchDrivers = async () => {
         setLoading(true)
         const response = await getAllDrivers()
         setLoading(false)

         if (response.isSuccess) {
            setDrivers(
               response.data.map((d) => {
                  return {
                     ...d,
                     selected: false,
                  }
               })
            )
         } else sendErrorNotification(response.message)
      }

      fetchDrivers()
   }, [])

   const [isDeleting, setIsDeleting] = useState(false)
   return (
      <div className='flex flex-1 flex-col h-full pt-10 pl-5 pr-2'>
         <div className='flex flex-row items-center '>
            <label className='main-title'>Drivers Panel</label>
            <div className='flex flex-1 ml-3 justify-between'>
               <Input
                  placeholder='Search Driver'
                  className='w-1/4 justify-start'
                  onChange={(e) => {
                     setSearch(e.target.value.toLowerCase())
                  }}
               />
               <Popconfirm
                  disabled={!drivers.some((d) => d.selected)}
                  title='Are you sure?'
                  onConfirm={async () => {
                     setIsDeleting(true)
                     const response = await deleteDrivers(
                        drivers.filter((d) => d.selected).map((d) => d.id)
                     )
                     setIsDeleting(false)

                     if (response.isSuccess) {
                        message.info(response.message)
                        setDrivers(drivers.filter((d) => !d.selected))
                     } else message.error(response.message)
                  }}>
                  <Button
                     loading={isDeleting}
                     icon={<DeleteOutlined />}
                     disabled={!drivers.some((d) => d.selected)}
                     type='primary'>
                     Delete Selected Drivers
                  </Button>
               </Popconfirm>
            </div>
         </div>
         <div className='mt-4 flex flex-col flex-1'>
            {loading ? (
               <div className='flex flex-1 items-center justify-center'>
                  <Spin />
               </div>
            ) : (
               <Table dataSource={filteredDrivers} columns={columns} />
            )}
         </div>
      </div>
   )
}
