import { doAuthorizedGetCall, doAuthorizedPostCall } from "./axiosHelper"

export async function getAllDrivers() {
   return (await doAuthorizedGetCall("/api/drivers/all")).data
}

export async function updateDriver(updateModel) {
   return (await doAuthorizedPostCall("/api/drivers/update", updateModel)).data
}

export async function deleteDrivers(deleteData) {
   return (await doAuthorizedPostCall("/api/drivers/delete", deleteData)).data
}

export async function updateDriverPassword(data) {
   return (await doAuthorizedPostCall("/api/drivers/update-password", data))
      .data
}
