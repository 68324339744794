import axios from "axios"
import { getAuthorizationHeader } from "./adminController"
import { errorResponse } from "../helpers/response"

export async function doAuthorizedGetCall(api) {
   try {
      return await axios.get(api, {
         headers: await getAuthorizationHeader(),
      })
   } catch (e) {
      return errorResponse(e.message)
   }
}

export async function doAuthorizedPostCall(api, data) {
   try {
      return await axios.post(api, data, {
         headers: await getAuthorizationHeader(),
      })
   } catch (e) {
      return errorResponse(e.message)
   }
}
