import { Button } from "antd"
import React from "react"
import { useNavigate } from "react-router-dom"

export default function SideBarButton({
   title,
   path,
   currentPath,
   className,
   icon,
   to,
   ...props
}) {
   const navigate = useNavigate()
   return (
      <Button
         className={
            (currentPath.endsWith(path)
               ? "selected-side-item"
               : "unselected-side-item") +
            " side-item" +
            (!className ? "" : " " + className)
         }
         onClick={() => {
            navigate(to)
         }}
         icon={icon}>
         <span className='line-height-0'>{title}</span>
      </Button>
   )
}
