import { Layout, Spin } from "antd"
import React, { useEffect, useState } from "react"
import {
   Route,
   Routes,
   Navigate,
   useLocation,
   Link,
   useNavigate,
} from "react-router-dom"
import AdminsView from "./dashboardviews/AdminsView"
import DriversView from "./dashboardviews/DriversView"
import DispatchersView from "./dashboardviews/DispatchersView"
import Logo from "../images/logo.png"
import SideBarButton from "./dashboardcomponents/SideBarButton"
import DashboardView from "./dashboardviews/DashboardView"
import {
   MdAccountCircle,
   MdDashboard,
   MdDriveEta,
   MdOutlineAirlineSeatReclineNormal,
   MdSettings,
} from "react-icons/md"
import PassengersView from "./dashboardviews/PassengersView"
import { sendSuccessNotification } from "../../helpers/notification"
import SettingsView from "./dashboardviews/SettingsView"
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth"

const { Content, Sider } = Layout

export default function Dashboard() {
   const defualtIconProps = {
      className: "mr-2",
      style: {
         fontSize: "20px",
         display: "inline-block",
         textAlign: "center",
      },
   }
   const location = useLocation()
   const navigate = useNavigate()
   const [checking, setChecking] = useState(false)

   useEffect(() => {
      const checkStatus = async () => {
         setChecking(true)

         onAuthStateChanged(getAuth(), async (user) => {
            if (!user) {
               navigate("/login")
            }

            setChecking(false)
         })
      }

      checkStatus()
   }, [])

   if (checking)
      return (
         <div className='flex flex-1 h-full items-center justify-center'>
            <Spin size='large' />
         </div>
      )

   return (
      <Layout className='h-full'>
         <Sider
            width={225}
            className='flex flex-1 sider flex-col'
            collapsedWidth={0}
            breakpoint='md'>
            <div
               className='d-flex h-full flex-col flex-1 items-center justify-center'
               style={{ padding: "57px 37px" }}>
               <div className='flex flex-1 flex-col h-full'>
                  <img
                     style={{ height: "40", width: "126px", margin: "auto" }}
                     src={Logo}
                     alt='logo'
                  />
                  <h2
                     className='text-secondary mt-2 justify-center items-center text-center'
                     style={{ marginBottom: "70px" }}>
                     Admin Panel
                  </h2>

                  <div className='flex mt-2 flex-col'>
                     <SideBarButton
                        to={`/dashboard`}
                        title='Dashboard'
                        path='dashboard'
                        className={"mt-2"}
                        currentPath={location.pathname}
                        icon={<MdDashboard {...defualtIconProps} />}
                     />
                     <SideBarButton
                        to={`/dashboard/admins`}
                        title='Admins'
                        path='admins'
                        className={"mt-2"}
                        currentPath={location.pathname}
                        icon={<MdAccountCircle {...defualtIconProps} />}
                     />
                     <SideBarButton
                        to={`/dashboard/drivers`}
                        title='Drivers'
                        className='mt-2'
                        path='drivers'
                        currentPath={location.pathname}
                        icon={<MdDriveEta {...defualtIconProps} />}
                     />
                     <SideBarButton
                        to={`/dashboard/dispatchers`}
                        title='Dispatchers'
                        className='mt-2'
                        path='dispatchers'
                        currentPath={location.pathname}
                        icon={<MdDriveEta {...defualtIconProps} />}
                     />

                     <SideBarButton
                        to={`/dashboard/passengers`}
                        title='Passengers'
                        path='passengers'
                        className={"mt-2"}
                        currentPath={location.pathname}
                        icon={
                           <MdOutlineAirlineSeatReclineNormal
                              {...defualtIconProps}
                           />
                        }
                     />
                     <SideBarButton
                        to={`/dashboard/settings`}
                        title='Settings'
                        path='settings'
                        className={"mt-2"}
                        currentPath={location.pathname}
                        icon={<MdSettings {...defualtIconProps} />}
                     />
                  </div>
                  <div className='flex flex-1 mt-2 flex-col items-center justify-end'>
                     <h1 className='text-bold'>V0.0.2</h1>
                     <Link
                        onClick={async () => {
                           await signOut(getAuth())
                           sendSuccessNotification("Signout Successfully!")
                           return false
                        }}
                        className='logout-link'
                        to='/'>
                        Logout
                     </Link>
                  </div>
               </div>
            </div>
         </Sider>
         <Content className='h-full main-container p-3 overflow-auto'>
            <Routes>
               <Route path='/' element={<DashboardView />} />
               <Route path='/passengers' element={<PassengersView />} />
               <Route path='admins' element={<AdminsView />} />
               <Route path='/drivers' element={<DriversView />} />
               <Route path='dispatchers' element={<DispatchersView />} />
               <Route path='/settings' element={<SettingsView />} />
               <Route path='*' element={<Navigate to={"/dashboard"} />} />
            </Routes>
         </Content>
      </Layout>
   )
}
