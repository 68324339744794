import { Button, Divider, Popconfirm, Spin, Table } from "antd"
import React, { useEffect, useState } from "react"
import {
   deleteAdmin,
   getAllAdmins,
} from "../../../controllers/adminManagementController"
import AddUpdateAdmin from "./adminscomponents/AddUpdateAdmin"
import {
   sendErrorNotification,
   sendSuccessNotification,
} from "../../../helpers/notification"

export default function AdminsView() {
   const setLoading = (id, value) => {
      setAdmins(
         admins.map((a) => {
            if (a.id === id) {
               a.loading = value
            }
            return a
         })
      )
   }
   const adminColumns = [
      {
         title: "Id",
         dataIndex: "id",
         key: "id",
      },
      {
         title: "Name",
         dataIndex: "name",
         key: "name",
      },
      { title: "Email", dataIndex: "email", key: "email" },
      {
         title: "Action",
         render: (text, record) => (
            <div className='flex flex-row'>
               <AddUpdateAdmin
                  data={record}
                  id={record.id}
                  onUpdate={(a) => {
                     setAdmins(
                        admins.map((admin) => {
                           if (admin.id === a.id) {
                              return a
                           }
                           return admin
                        })
                     )
                  }}
                  type={"update"}
               />
               <Divider type='vertical' />
               {record.loading ? (
                  <Spin />
               ) : (
                  <Popconfirm
                     title='Are you sure?'
                     onConfirm={async () => {
                        setLoading(record.id, true)

                        const response = await deleteAdmin(record.id)

                        if (response.isSuccess) {
                           sendSuccessNotification(response.message)
                           setAdmins(admins.filter((a) => a.id !== record.id))
                        } else {
                           sendErrorNotification(response.message)
                           setLoading(record.id, false)
                        }
                     }}>
                     <Button type='link' className='text-red-600'>
                        Delete
                     </Button>
                  </Popconfirm>
               )}
            </div>
         ),
      },
   ]

   const [admins, setAdmins] = useState([])
   const [adminsLoading, setAdminsLoading] = useState(true)
   useEffect(() => {
      const getAdmins = async () => {
         const response = await getAllAdmins()
         setAdminsLoading(false)

         if (response.isSuccess) {
            setAdmins(
               response.data.map((a) => {
                  return {
                     ...a,
                     loading: false,
                  }
               })
            )
         } else {
            sendErrorNotification("Unable to fetch admins")
         }
      }

      getAdmins()
   }, [])
   return (
      <div className='h-full flex pt-10 pl-5 pr-10 flex-col'>
         <div className='flex flex-row'>
            <label className='main-title flex-1'>Admins Panel</label>
            <AddUpdateAdmin
               type='add'
               onAdd={(admin) => {
                  setAdmins([...admins, admin])
               }}
            />
         </div>
         <div className='flex flex-1 mt-2 flex-col overflow-auto'>
            <Table
               loading={adminsLoading}
               columns={adminColumns}
               dataSource={admins}
            />
         </div>
      </div>
   )
}
