import Login from "./components/screens/Login"
import {
   Route,
   Routes,
   BrowserRouter as Router,
   Navigate,
} from "react-router-dom"
import "./index.scss"
import "./styles/css/antd.css"
import Dashboard from "./components/screens/Dashboard"
import "font-awesome/css/font-awesome.min.css"
import "./firebase"

function App() {
   return (
      <Router>
         <Routes>
            <Route path='login' element={<Login />} />
            <Route path='dashboard/*' element={<Dashboard />} />
            <Route path='*' element={<Navigate to={"login"} />} />
         </Routes>
      </Router>
   )
}

export default App
