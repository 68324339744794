import { doAuthorizedGetCall, doAuthorizedPostCall } from "./axiosHelper"
import { errorResponse } from "../helpers/response"

export async function getSettings() {
   try {
      const response = await doAuthorizedGetCall("/api/settings/get")

      return response.data
   } catch (e) {
      errorResponse(e)
   }
}

export async function updateDispatcherSettings(settings) {
   try {
      const response = await doAuthorizedPostCall(
         "/api/settings/dispatcher/update",
         settings
      )

      return response.data
   } catch (e) {
      errorResponse(e)
   }
}
