import React, { useEffect, useState } from "react"
import {
   deletePassengers,
   getAllPassengers,
} from "../../../controllers/passengersController"
import { message, Button, Space, Table, Tag, Popconfirm, Checkbox } from "antd"
import Loading from "../../../components/Loading"
import { FaTrash } from "react-icons/fa"
import { DeleteOutlined } from "@ant-design/icons"

export default function PassengersView() {
   const [passengers, setPassengers] = useState([])
   const [loading, setLoading] = useState(false)

   const columns = [
      {
         title: () => {
            return (
               <Checkbox
                  onChange={(x) => {
                     setPassengers(
                        passengers.map((d) => {
                           return { ...d, selected: x.target.checked }
                        })
                     )
                  }}
                  checked={passengers.every((d) => d.selected)}
                  className=''>
                  Name
               </Checkbox>
            )
         },
         dataIndex: "name",
         key: "name",
         render: (text, record) => (
            <Checkbox
               onChange={(x) => {
                  setPassengers(
                     passengers.map((d) => {
                        if (d.id === record.id) {
                           return { ...d, selected: x.target.checked }
                        }

                        return d
                     })
                  )
               }}
               checked={record.selected}
               className=''>
               {text}
            </Checkbox>
         ),
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "Phone",
         dataIndex: "phone",
         key: "phone",
      },
      {
         title: "Vip",
         dataIndex: "vip",
         render: (v, r) => {
            return <Tag color={v ? "green" : "yellow"}>{v ? "Yes" : "No"}</Tag>
         },
      },
   ]

   useEffect(() => {
      const loadPassengers = async () => {
         setLoading(true)
         const repsonse = await getAllPassengers()
         setLoading(false)
         if (!repsonse.isSuccess) {
            message.error("Unable to load passengers")
            return
         }

         setPassengers(repsonse.data)
      }

      loadPassengers()
   }, [])

   const [isDeleting, setIsDeleting] = useState(false)
   return (
      <div className='flex flex-1 flex-col h-full pt-10 pl-5 pr-10'>
         <div className='flex flex-row justify-between'>
            <label className='main-title'>Passengers Panel</label>
            <Popconfirm
               title='Are you sure?'
               onConfirm={async () => {
                  setIsDeleting(true)

                  const response = await deletePassengers(
                     passengers.filter((d) => d.selected).map((d) => d.id)
                  )
                  setIsDeleting(false)

                  if (response.isSuccess) {
                     setPassengers(passengers.filter((d) => !d.selected))
                     message.success(response.message)
                  } else message.error(response.message)
               }}>
               <Button
                  loading={isDeleting}
                  icon={<DeleteOutlined />}
                  disabled={!passengers.some((d) => d.selected)}
                  type='primary'>
                  Delete Selected Passengers
               </Button>
            </Popconfirm>
         </div>
         <div className='mt-4 flex flex-col flex-1'>
            {loading ? (
               <Loading />
            ) : (
               <Table dataSource={passengers} columns={columns} />
            )}
         </div>
      </div>
   )
}
