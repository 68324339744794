import {
   Button,
   Col,
   Form,
   Input,
   message,
   Modal,
   Popconfirm,
   Radio,
   Row,
   Space,
   Switch,
} from "antd"
import React, { useState } from "react"
import {
   deleteDrivers,
   updateDriver,
   updateDriverPassword,
} from "../../../../controllers/driversController"
import {
   sendErrorNotification,
   sendSuccessNotification,
} from "../../../../helpers/notification"
import { DeleteOutlined } from "@ant-design/icons"

export default function UpdateDriver({ driver, onUpdate, onDelete }) {
   const [loading, setLoading] = useState(false)
   const [updateDriverModel, setUpdateDriverModel] = useState({
      id: driver.id,
      status: driver.verification.status,
      level: driver.verification.level,
   })

   const [show, setShow] = useState(false)
   const [isDeleting, setIsDeleting] = useState(false)
   const [updatingPassword, setUpdatingPassword] = useState(false)
   const [form] = Form.useForm()

   return (
      <>
         <Modal
            className='w-5/6 '
            okText='Update Information'
            visible={show}
            okButtonProps={{
               loading: loading,
               disabled: isDeleting,
            }}
            onOk={async () => {
               setLoading(true)
               const response = await updateDriver(updateDriverModel)
               setLoading(false)

               if (response.isSuccess) {
                  onUpdate(updateDriverModel)

                  sendSuccessNotification(response.message)
                  setShow(false)
               } else sendErrorNotification(response.message)
            }}
            onCancel={() => setShow(false)}>
            <label className='main-title'>Update Information</label>

            <div className='flex flex-col mt-4'>
               <div className='bg-zinc-100 rounded-md p-2'>
                  <Row>
                     <Col span={8} className='p-2'>
                        <span>User Name</span>
                        <Input
                           className='mt-2 primary-input'
                           readOnly
                           value={driver.username}
                           placeholder='User Name'
                        />
                     </Col>
                     <Col span={8} className='p-2'>
                        <span>Email</span>
                        <Input
                           className='mt-2 primary-input'
                           readOnly
                           value={driver.email}
                           placeholder='Email'
                        />
                     </Col>
                     <Col span={8} className='p-2'>
                        <span>Phone Number</span>
                        <Input
                           value={driver.phone}
                           className='mt-2 primary-input'
                           readOnly
                           placeholder='Phone Number'
                        />
                     </Col>
                  </Row>
                  <Row>
                     <Col span={8} className='p-2'>
                        <span>Car Make</span>
                        <Input
                           className='mt-2 primary-input'
                           readOnly
                           value={driver.vehicle.make}
                           placeholder='Car Make'
                        />
                     </Col>
                     <Col span={8} className='p-2'>
                        <span>Car Model</span>
                        <Input
                           className='mt-2 primary-input'
                           readOnly
                           value={driver.vehicle.model}
                           placeholder='Email'
                        />
                     </Col>
                     <Col span={8} className='p-2'>
                        <span>Car Color</span>
                        <Input
                           className='mt-2 primary-input'
                           readOnly
                           value={driver.vehicle.color}
                           placeholder='Email'
                        />
                     </Col>
                  </Row>
                  <Row>
                     <Col span={8} className='p-2'>
                        <Space size={"middle"} className='mt-1'>
                           <span>
                              <div>
                                 <label>Wifi?</label>
                              </div>
                              <Switch readOnly checked={driver.vehicle.wifi} />
                           </span>
                           <span>
                              <div>
                                 <label>Regional Trips?</label>
                              </div>
                              <Switch checked={driver.vehicle.regionalTrips} />
                           </span>
                        </Space>
                     </Col>
                  </Row>
                  <Row>
                     <Col className='p-2'>
                        <label>Status</label>
                        <div className='mt-2'>
                           <Radio.Group
                              value={updateDriverModel.status}
                              onChange={(v) => {
                                 setUpdateDriverModel({
                                    ...updateDriverModel,
                                    status: v.target.value,
                                 })
                              }}>
                              <Radio.Button value={0}>
                                 Not Selected
                              </Radio.Button>
                              <Radio.Button value={1}>Pending</Radio.Button>
                              <Radio.Button value={2}>Approve</Radio.Button>
                              <Radio.Button value={3}>Blocked</Radio.Button>
                           </Radio.Group>
                        </div>
                     </Col>
                  </Row>
                  <Row>
                     <Col span={24} className='p-2'>
                        <div className='flex flex-row flex-1'>
                           <div>
                              <label>Level</label>
                              <div className='mt-2'>
                                 <Radio.Group
                                    onChange={(v) => {
                                       setUpdateDriverModel({
                                          ...updateDriverModel,
                                          level: v.target.value,
                                       })
                                    }}
                                    value={updateDriverModel.level}>
                                    <Radio.Button value={1}>
                                       Silver
                                    </Radio.Button>
                                    <Radio.Button value={2}>Gold</Radio.Button>
                                    <Radio.Button value={3}>
                                       Platinum
                                    </Radio.Button>
                                 </Radio.Group>
                              </div>
                           </div>
                           <div className='flex flex-1 justify-end items-end'>
                              <Popconfirm
                                 onConfirm={async () => {
                                    setIsDeleting(true)
                                    const response = await deleteDrivers([
                                       driver.id,
                                    ])
                                    setIsDeleting(false)

                                    if (response.isSuccess) {
                                       message.info(response.message)
                                       if (onDelete) onDelete()
                                       setShow(false)
                                    } else message.error(response.message)
                                 }}
                                 title='Are you sure?'>
                                 <Button
                                    disabled={loading}
                                    loading={isDeleting}
                                    icon={<DeleteOutlined />}
                                    type='link'
                                    color='danger'>
                                    Delete Driver
                                 </Button>
                              </Popconfirm>
                           </div>
                        </div>
                     </Col>
                  </Row>
               </div>

               <div className='mt-2 rounded-md bg-orange-100'>
                  <div className='flex flex-col'>
                     <div className='flex flex-row justify-between pt-6 pl-2 pr-2'>
                        <Form
                           onFinish={async (a) => {
                              setUpdatingPassword(true)
                              message.info("Updating password...")
                              const response = await updateDriverPassword({
                                 id: driver.id,
                                 password: a.password,
                              })

                              if (response.isSuccess) {
                                 message.success(response.message)
                              } else message.error(response.message)

                              setUpdatingPassword(false)
                           }}
                           form={form}
                           className='w-2/6'>
                           <Form.Item
                              name={"password"}
                              rules={[
                                 {
                                    required: true,
                                    message: "Please input your password!",
                                 },
                                 {
                                    min: 6,
                                    message:
                                       "Password must be at least 6 characters!",
                                 },
                                 {
                                    max: 20,
                                    message:
                                       "Password must be less than 20 characters!",
                                 },
                              ]}>
                              <Input.Password placeholder='New Password' />
                           </Form.Item>
                        </Form>
                        <Button
                           loading={updatingPassword}
                           onClick={() => form.submit()}
                           type='primary'>
                           Update Password{" "}
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </Modal>

         <Button
            type='link'
            onClick={() => {
               setShow(true)
            }}>
            View
         </Button>
      </>
   )
}
