import { errorResponse } from "../helpers/response"
import { doAuthorizedGetCall, doAuthorizedPostCall } from "./axiosHelper"

export async function getAllPassengers() {
   try {
      return (await doAuthorizedGetCall("/api/passengers/all")).data
   } catch (e) {
      return errorResponse(e.message)
   }
}

export async function deletePassengers(deleteData) {
   return (await doAuthorizedPostCall("/api/passengers/delete", deleteData))
      .data
}
