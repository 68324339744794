import { Button, message, Select, Spin } from "antd"
import React, { useEffect, useState } from "react"
import {
   getSettings,
   updateDispatcherSettings,
} from "../../../controllers/settingsController"

const { Option } = Select

export default function SettingsView() {
   const [loading, setLoading] = useState(false)
   const [updatingDispatcherSettings, setUpdatingDispatcherSettings] =
      useState(false)

   const [settings, setSettings] = useState({
      dispatcher: {
         defaultTimezone: "-6:00",
      },
   })

   const updateData = (type, field, value) => {
      setSettings({
         ...settings,
         [type]: {
            ...settings[type],
            [field]: value,
         },
      })
   }

   const updateDispatcherSettingsL = async () => {
      setUpdatingDispatcherSettings(true)
      message.info("Updating settings...")

      const response = await updateDispatcherSettings(settings.dispatcher)
      console.log(response)

      if (response.isSuccess) {
         message.success(response.message)
      } else message.error(response.message)

      setUpdatingDispatcherSettings(false)
   }

   useEffect(() => {
      const loadData = async () => {
         setLoading(true)
         const s = await getSettings()
         console.log(s)
         setLoading(false)
         setSettings({
            ...settings,
            ...s.data,
         })
      }

      loadData()
   }, [])
   return (
      <div className='flex flex-1 flex-col h-full pt-10 pl-5 pr-10'>
         <div className='flex flex-row justify-between'>
            <label className='main-title'>Settings </label>
         </div>
         <div className='flex flex-1 mt-5 w-full'>
            <Spin
               wrapperClassName='w-full'
               spinning={loading}
               className='w-full'>
               <div className='flex flex-1 flex-col'>
                  <div className='flex flex-1 justify-between'>
                     <h1 className='text-lg'>Default Time Zone</h1>
                     <Select
                        onChange={(e) => {
                           updateData("dispatcher", "defaultTimezone", e)
                        }}
                        value={settings.dispatcher.defaultTimezone}
                        defaultValue={"-6:00"}>
                        <Option value='-6:00'>
                           Central Standard Time (-06:00)
                        </Option>
                        <Option value='-5:00'>
                           Central Daylight Time (-5:00)
                        </Option>
                     </Select>
                  </div>
                  <div className='flex justify-end'>
                     <Button
                        onClick={updateDispatcherSettingsL}
                        type='primary'
                        loading={updatingDispatcherSettings}>
                        Update
                     </Button>
                  </div>
               </div>
            </Spin>
         </div>
      </div>
   )
}
