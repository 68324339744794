import React, { useEffect, useState } from "react"
import { sendErrorNotification } from "../../../helpers/notification"
import { Button, Checkbox, message, Popconfirm, Spin, Table, Tag } from "antd"
import {
   deleteDispatcher,
   getAllDispatchers,
} from "../../../controllers/dispatcherManagementController"
import UpdateDispatcher from "./dispatcherComponents/UpdateDispatcher"
import { FaTrash } from "react-icons/fa"
import { DeleteOutlined } from "@ant-design/icons"

export default function DispatchersView() {
   const columns = [
      {
         title: () => {
            return (
               <Checkbox
                  onChange={(x) => {
                     setDispatchers(
                        dispatchers.map((d) => {
                           return { ...d, selected: x.target.checked }
                        })
                     )
                  }}
                  checked={dispatchers.every((d) => d.selected)}
                  className=''>
                  Name
               </Checkbox>
            )
         },
         dataIndex: "name",
         key: "name",
         render: (text, record) => (
            <Checkbox
               onChange={(x) => {
                  setDispatchers(
                     dispatchers.map((d) => {
                        if (d.id === record.id) {
                           return { ...d, selected: x.target.checked }
                        }

                        return d
                     })
                  )
               }}
               checked={record.selected}
               className=''>
               {text}
            </Checkbox>
         ),
      },
      {
         title: "Email",
         dataIndex: "email",
         key: "email",
      },
      {
         title: "Trips Count",
         dataIndex: "tripsCount",
         key: "tripsCount",
         render: (t, r) => {
            if (!t) return "0"

            return t
         },
      },
      {
         title: "Status",
         dataIndex: "verification",
         render: (v, r) => {
            if (v === undefined)
               v = {
                  status: 0,
               }
            return (
               <>
                  <Tag
                     color={
                        v.status === 0
                           ? "yellow"
                           : v.status === 1
                           ? "blue"
                           : v.status === 2
                           ? "green"
                           : "red"
                     }>
                     {v.status === 0
                        ? "Not Selected"
                        : v.status === 1
                        ? "Pending"
                        : v.status === 2
                        ? "Approved"
                        : "Blocked"}
                  </Tag>
                  <UpdateDispatcher
                     onUpdate={(us) => {
                        setDispatchers(
                           dispatchers.map((d) => {
                              if (d.id === r.id) {
                                 return {
                                    ...d,
                                    verification: {
                                       ...v,
                                       status: us,
                                    },
                                 }
                              }

                              return d
                           })
                        )
                     }}
                     id={r.id}
                     status={v.status}
                  />
               </>
            )
         },
      },
   ]

   const [dispatchers, setDispatchers] = useState([])
   const [loading, setLoading] = useState(false)

   useEffect(() => {
      const fetchDispatchers = async () => {
         setLoading(true)
         const response = await getAllDispatchers()
         console.log(response)
         setLoading(false)
         if (response.isSuccess) {
            setDispatchers(response.data)
         } else sendErrorNotification(response.message)
      }

      fetchDispatchers()
   }, [])
   const [isDeleting, setIsDeleting] = useState(false)
   return (
      <div className='flex flex-1 flex-col h-full pt-10 pl-5 pr-10'>
         <div className='flex flex-row justify-between'>
            <label className='main-title'>Dispatchers Panel</label>
            <Popconfirm
               title='Are you sure?'
               onConfirm={async () => {
                  setIsDeleting(true)
                  const response = await deleteDispatcher(
                     dispatchers.filter((d) => d.selected).map((d) => d.id)
                  )
                  setIsDeleting(false)

                  if (response.isSuccess) {
                     setDispatchers(dispatchers.filter((d) => !d.selected))
                     message.success(response.message)
                  } else message.error(response.message)
               }}>
               <Button
                  disabled={!dispatchers.some((d) => d.selected)}
                  loading={isDeleting}
                  icon={<DeleteOutlined />}
                  type='primary'>
                  Delete Selected Dispatchers
               </Button>
            </Popconfirm>
         </div>
         <div className='mt-4 flex flex-col flex-1'>
            {loading ? (
               <div className='flex flex-1 items-center justify-center'>
                  <Spin />
               </div>
            ) : (
               <Table dataSource={dispatchers} columns={columns} />
            )}
         </div>
      </div>
   )
}
