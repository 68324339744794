import { UserAddOutlined } from "@ant-design/icons/lib/icons"
import { Alert, Button, Col, Input, Modal, Row } from "antd"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
   createAdmin,
   updateAdmin,
} from "../../../../controllers/adminManagementController"
import { sendSuccessNotification } from "../../../../helpers/notification"

export default function AddUpdateAdmin({
   data,
   type,
   id,
   onUpdate,
   onAdd,
   ...props
}) {
   const [userData, setUserData] = useState({
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
   })

   const [loading, setLoading] = useState(false)

   useEffect(() => {
      if (data) {
         setUserData({
            ...userData,
            name: data.name,
            email: data.email,
         })
      }
   }, [data])

   const [error, setError] = useState("")

   const addAdmin = async () => {
      setLoading(true)
      if (userData.password !== userData.confirmPassword) {
         setError("Password and Confirm Password must be same")
         setLoading(false)
         return
      }

      setError(null)

      var model = {
         name: userData.name,
         email: userData.email,
         password: userData.password,
      }

      var response = null

      if (type === "update") {
         model.id = id

         response = await updateAdmin(model)
      } else {
         response = await createAdmin(model)
      }
      setLoading(false)

      if (response.isSuccess) {
         sendSuccessNotification(response.message)
         if (type === "update") {
            onUpdate(response.data)
         } else {
            onAdd(response.data)
         }
         setShow(false)
      } else setError(response.combinedErrors)
   }

   const [show, setShow] = useState(false)

   return (
      <>
         <Modal footer={null} visible={show} onCancel={() => setShow(false)}>
            <label className='main-title'>
               {type === "add" ? "Add " : "Update "} Information
            </label>
            <Row className='mt-2'>
               <Col span={12} className='p-1    '>
                  <label className='text-base' htmlFor='name'>
                     Name
                  </label>
                  <Input
                     className='h-10'
                     autoComplete='off'
                     value={userData.name}
                     onChange={(e) =>
                        setUserData({
                           ...userData,
                           name: e.target.value,
                        })
                     }
                  />
               </Col>
               <Col span={12} className='p-1'>
                  <label className='text-base' htmlFor='email'>
                     Email
                  </label>
                  <Input
                     className='h-10'
                     autoComplete='off'
                     value={userData.email}
                     onChange={(e) =>
                        setUserData({
                           ...userData,
                           email: e.target.value,
                        })
                     }
                  />
               </Col>
            </Row>
            <Row className='mt-1'>
               <Col span={12} className='p-1'>
                  <label className='text-base' htmlFor='name'>
                     Password
                  </label>
                  <Input.Password
                     className='h-10'
                     value={userData.password}
                     onChange={(e) =>
                        setUserData({
                           ...userData,
                           password: e.target.value,
                        })
                     }
                  />
               </Col>
               <Col span={12} className='p-1'>
                  <label className='text-base' htmlFor='name'>
                     Confirm Password
                  </label>
                  <Input.Password
                     className='h-10'
                     value={userData.confirmPassword}
                     onChange={(e) =>
                        setUserData({
                           ...userData,
                           confirmPassword: e.target.value,
                        })
                     }
                  />
               </Col>
            </Row>
            <Row className='p-1'>
               <Col span={24}>
                  {error && error !== "" ? (
                     <Alert
                        showIcon={true}
                        type='error'
                        message={error}></Alert>
                  ) : (
                     <></>
                  )}
               </Col>
            </Row>
            <Row className='mt-1'>
               <Col span={8} className='p-1'>
                  <Button
                     loading={loading}
                     onClick={addAdmin}
                     icon={<UserAddOutlined />}
                     className='secondary-button w-full'>
                     {type === "add" ? "Add" : "Update"}
                  </Button>
               </Col>
            </Row>
         </Modal>
         {type === "update" ? (
            <Button type='link' onClick={() => setShow(true)}>
               Update
            </Button>
         ) : (
            <Button
               className='secondary-button'
               onClick={() => setShow(true)}
               icon={<UserAddOutlined />}>
               Add Admin
            </Button>
         )}
      </>
   )
}
