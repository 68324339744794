import { Button, Form, Input, message, Modal, Radio } from "antd"
import React, { useState } from "react"
import {
   updateDispatcher,
   updateDispatcherPassword,
} from "../../../../controllers/dispatcherManagementController"
import {
   sendErrorNotification,
   sendSuccessNotification,
} from "../../../../helpers/notification"

export default function UpdateDispatcher({ id, status, onUpdate }) {
   const [show, setShow] = useState(false)
   const [loading, setLoading] = useState(false)
   const [updatedStatus, setUpdatedStatus] = useState(status)

   const [updatingPassword, setUpdatingPassword] = useState(false)
   const [form] = Form.useForm()

   return (
      <>
         <Modal
            visible={show}
            onCancel={() => setShow(false)}
            okText='Update'
            className='w-3/6'
            onOk={async () => {
               setLoading(true)
               const response = await updateDispatcher({
                  id: id,
                  status: updatedStatus,
               })

               setLoading(false)

               if (response.isSuccess) {
                  sendSuccessNotification(
                     "Dispatcher status updated successfully"
                  )
                  setShow(false)
                  if (onUpdate) onUpdate(updatedStatus)
               } else sendErrorNotification(response.message)
            }}
            title='Update Dispatcher'
            okButtonProps={{
               loading: loading,
            }}>
            <div className='p-2 flex flex-1 flex-col'>
               <label className='text-xl mb-3'>Status</label>
               <Radio.Group
                  value={updatedStatus}
                  onChange={(v) => {
                     setUpdatedStatus(v.target.value)
                  }}>
                  <Radio.Button value={0}>Not Selected</Radio.Button>
                  <Radio.Button value={1}>Pending</Radio.Button>
                  <Radio.Button value={2}>Approve</Radio.Button>
                  <Radio.Button value={3}>Blocked</Radio.Button>
               </Radio.Group>
               <div className='mt-5 rounded-md bg-orange-100 '>
                  <div className='flex flex-col'>
                     <div className='flex flex-row justify-between pt-6 pl-2 pr-2'>
                        <Form
                           onFinish={async (a) => {
                              setUpdatingPassword(true)
                              message.info("Updating password...")
                              const response = await updateDispatcherPassword({
                                 id: id,
                                 password: a.password,
                              })

                              if (response.isSuccess) {
                                 message.success(response.message)
                              } else message.error(response.message)

                              setUpdatingPassword(false)
                           }}
                           form={form}
                           className='w-4/6'>
                           <Form.Item
                              name={"password"}
                              rules={[
                                 {
                                    required: true,
                                    message: "Please input your password!",
                                 },
                                 {
                                    min: 6,
                                    message:
                                       "Password must be at least 6 characters!",
                                 },
                                 {
                                    max: 20,
                                    message:
                                       "Password must be less than 20 characters!",
                                 },
                              ]}>
                              <Input.Password placeholder='New Password' />
                           </Form.Item>
                        </Form>
                        <Button
                           loading={updatingPassword}
                           onClick={() => form.submit()}
                           type='primary'>
                           Update Password{" "}
                        </Button>
                     </div>
                  </div>
               </div>
            </div>
         </Modal>
         <Button type='link' onClick={() => setShow(true)}>
            Update
         </Button>
      </>
   )
}
