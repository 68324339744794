import { errorResponse } from "../helpers/response"
import { doAuthorizedGetCall } from "./axiosHelper"

export async function getOveriew() {
   try {
      return (await doAuthorizedGetCall("api/overview/get")).data
   } catch (e) {
      return errorResponse("Unable to load data")
   }
}
