import { Col, message, Row } from "antd"
import React, { useEffect, useState } from "react"
import { getOveriew } from "../../../controllers/overallController"
import Loading from "../../Loading"

export default function DashboardView() {
   const [loading, setLoading] = useState(true)
   const [data, setData] = useState({
      admins: 0,
      drivers: 0,
      passengers: 0,
      dispatchers: 0,
      trips: 0,
   })

   useEffect(() => {
      const loadData = async () => {
         setLoading(true)

         const response = await getOveriew()
         setLoading(false)
         if (!response.isSuccess) {
            message.error(response.message)
            return
         }
         setData(response.data)
      }

      loadData()
   }, [])

   const overview = [
      {
         title: "Number of Admins",
         valueKey: "adminsCount",
         bgColor: "#E3D9ED",
         valueColor: "#9D34FF",
         value: data.admins,
      },
      {
         title: "Number of Drivers",
         valueKey: "driversCount",
         bgColor: "#C5FADB",
         valueColor: "#00B94E",
         value: data.drivers,
      },
      {
         title: "Number of Passengers",
         valueKey: "passengersCount",
         bgColor: "#F0EAB5",
         valueColor: "#F4B000",
         value: data.passengers,
      },
      {
         title: "Number of Dispatchers",
         valueKey: "dispatchersCount",
         bgColor: "#C8E1F8",
         valueColor: "#00B9F4",
         value: data.dispatchers,
      },
      {
         title: "Number of Rides",
         valueKey: "ridesCount",
         bgColor: "#FFD3D3",
         valueColor: "#F0473D",
         value: data.trips,
      },
   ]

   return (
      <div className='h-full pt-10 pl-5 pr-10 flex flex-1 flex-col'>
         <label className='main-title'>Dasboard Panel</label>
         <div className='mt-10 flex flex-1 flex-col'>
            {loading ? (
               <Loading />
            ) : (
               <Row>
                  {overview.map((o) => {
                     return (
                        <Col
                           xs={24}
                           md={11}
                           lg={7}
                           span={7}
                           offset={1}
                           className='mt-5'>
                           <div
                              style={{ backgroundColor: o.bgColor }}
                              className='overview-card flex flex-col justify-center items-center'>
                              <div className='overview-card-title'>
                                 {o.title}
                              </div>
                              <div
                                 style={{ color: o.valueColor }}
                                 className='overview-card-value'>
                                 {o.value}
                              </div>
                           </div>
                        </Col>
                     )
                  })}
               </Row>
            )}
         </div>
      </div>
   )
}
