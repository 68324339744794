import { notification } from "antd"

export function sendSuccessNotification(message = "", title = "Message") {
   notification.success({
      message: title,
      description: message,
   })
}

export function sendErrorNotification(message = "", title = "Error") {
   notification.error({
      message: title,
      description: message,
   })
}
