import React, { useEffect, useState } from "react"
import { Col, Button, Alert, Input, Image, Spin, Form } from "antd"
import { LockOutlined, UserOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import loginBg from "../images/loginbg.png"
import logo from "../images/logo.png"
import {
   getAuth,
   onAuthStateChanged,
   signInWithEmailAndPassword,
   signOut,
} from "firebase/auth"
import { doc, getDoc, getFirestore } from "firebase/firestore"

export default function Login() {
   const isFirstCheck = React.useRef(true)
   useEffect(() => {
      onAuthStateChanged(getAuth(), async (user) => {
         if (isFirstCheck.current) {
            isFirstCheck.current = false
         } else return
         if (user) {
            setErrorText(null)
            navigate("/dashboard")
         } else {
            setLoading(false)
            setCheckingStatus(false)
         }
      })
   }, [])

   const navigate = useNavigate()
   const [checkingStatus, setCheckingStatus] = useState(true)
   const [errorText, setErrorText] = useState("")
   const [loading, setLoading] = useState(false)

   if (checkingStatus) {
      return (
         <div className='flex flex-1 h-full items-center justify-center'>
            <Spin size='large' />
         </div>
      )
   }

   return (
      <div
         className='h-full w-100 flex flex-1'
         style={{ background: `url("${loginBg}")`, backgroundSize: "cover" }}>
         <Col span={16}>
            <div className='flex justify-center items-center h-full flex-col'>
               <Image
                  alt='logo'
                  src={logo}
                  preview={false}
                  style={{ width: "325px", height: "auto" }}
               />
               <h3 className='login-label mt-3'>Admin Panel</h3>
            </div>
         </Col>
         <Col span={8} className='flex justify-center items-center '>
            <div
               className='login-container flex flex-col'
               style={{ padding: "38px" }}>
               <label className='mb-5 font-bold' style={{ fontSize: "24px" }}>
                  Login to Admin Panel
               </label>

               <div className='w-100 flex flex-1 flex-col justify-end'>
                  <Form
                     onFinish={async (v) => {
                        setLoading(true)
                        setErrorText(null)
                        try {
                           const response = await signInWithEmailAndPassword(
                              getAuth(),
                              v.email,
                              v.password
                           )

                           const adminRef = doc(
                              getFirestore(),
                              "admins",
                              response.user.uid
                           )
                           const admin = await getDoc(adminRef)

                           if (!admin.exists()) {
                              setErrorText("You are not an admin")
                              setLoading(false)

                              await signOut(getAuth())
                              return
                           } else {
                              setErrorText(null)
                              navigate("/dashboard")
                           }
                        } catch (e) {
                           switch (e.code) {
                              case "auth/user-not-found":
                                 setErrorText("User not found")
                                 break
                              case "auth/wrong-password":
                                 setErrorText("Wrong password")
                                 break
                              default:
                                 setErrorText("Unable to login")
                           }
                        }
                        setLoading(false)
                     }}
                     layout='vertical'
                     size='large'>
                     <Form.Item
                        name={"email"}
                        rules={[
                           {
                              required: true,
                              message: "Please enter your email",
                           },
                           {
                              type: "email",
                              message: "Please enter a valid email",
                           },
                        ]}
                        label='Email'>
                        <Input
                           placeholder='Enter Email'
                           type={"email"}
                           prefix={<UserOutlined />}
                        />
                     </Form.Item>
                     <Form.Item
                        rules={[
                           {
                              required: true,
                              message: "Please enter your password",
                           },
                           {
                              min: 6,
                              message: "Password must be at least 6 characters",
                           },
                           {
                              max: 20,
                              message: "Password must be at most 20 characters",
                           },
                        ]}
                        name={"password"}
                        label='Password'>
                        <Input.Password
                           placeholder='Password'
                           prefix={<LockOutlined />}
                        />
                     </Form.Item>
                     <Alert
                        message={errorText}
                        type='error'
                        className={
                           "mt-3 " +
                           (!errorText || errorText === "" ? "hidden" : "")
                        }
                     />
                     <Form.Item className='mt-4'>
                        <Button
                           className='w-full'
                           type='primary'
                           htmlType='submit'
                           loading={loading}>
                           Login
                        </Button>
                     </Form.Item>
                  </Form>
               </div>
            </div>
         </Col>
      </div>
   )
}
