const response = {
   isSuccess: false,
   message: "",
   errors: "",
   data: {},
}

export function errorResponse(message = "", data = null) {
   return {
      ...response,
      message: message,
      data: data,
   }
}

export function okResponse(data = null, message = "") {
   return {
      ...response,
      data: data,
      message: message,
      isSuccess: true,
   }
}
