import { doAuthorizedGetCall, doAuthorizedPostCall } from "./axiosHelper"

export async function getAllAdmins() {
   return (await doAuthorizedGetCall("/admin/all")).data
}

export async function createAdmin(admin) {
   return (await doAuthorizedPostCall("/admin/create", admin)).data
}

export async function updateAdmin(admin) {
   return (await doAuthorizedPostCall("/admin/update", admin)).data
}

export async function deleteAdmin(id) {
   return (await doAuthorizedGetCall("/admin/delete/" + id)).data
}
